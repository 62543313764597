@import '../../styles/customMediaQueries.css';

.table_wrapper {
  overflow: auto;
  width: 88%;
  height: 100vh;
  padding-inline: 20px;
  margin-inline: auto;
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 60px;
  padding-bottom: 40px;
  box-shadow: 0px 30px 84px 0px rgba(19, 10, 46, 0.08);

  @media (--viewportLarge) {
    width: 100%;
    max-width: 77.309vw;
    padding-inline: 20px;
    margin-bottom: 60px;
    border-radius: 24px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.table {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  margin-top: 20px;
}

.table-heading {
  position: sticky;
  top: 0;
  min-width: 124px;
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 13px;
  font-weight: 700;
  line-height: 25.12px;
  letter-spacing: 0.1px;
  text-align: left;
  background: #f0efea;
}

.table-heading:nth-child(8) {
  min-width: 250px;
}

.table-heading:first-child {
  border-top-left-radius: 8px;
}

.table-heading:nth-child(8) {
  border-top-right-radius: 8px;
}

.table-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.table-col a {
  text-decoration: underline;
  font-weight: 500;
}

.table-col,
.table-col ul li {
  font-size: 13px;
  font-weight: 400;
  line-height: 20.02px;
  letter-spacing: 0.15px;
  text-align: left;
}

.table-col ul li {
  margin: 0;
  padding: 0;
  list-style: inside;
}

.heading {
  position: sticky;
  top: 0;
}
